import Amplify from '@aws-amplify/core';
import { Component } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { AppConfig, ConfigureAmplify } from '@toca.ui/config';
import TagManager from 'react-gtm-module';

import './App.css';
import './TransitionAnimation.css';
import UserPortalRoutes from './views/UserPortalRoutes';
import { BrandContextProvider } from 'contexts/BrandContext';

const tagManagerArgs = {
  gtmId: AppConfig.GtmKey,
};
TagManager.initialize(tagManagerArgs);

ConfigureAmplify(Amplify);

export default class App extends Component {
  render() {
    return (
      <BrandContextProvider>
        <Router>
          <UserPortalRoutes />
        </Router>
      </BrandContextProvider>
    );
  }
}
