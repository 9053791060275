import { createContext, useMemo, useReducer } from 'react';
import * as ContextTypes from './ContextTypes';
import ContextReducer from './ContextReducer';
import { hideOpenInAppMsgAction } from './ContextAction';

const initailState: ContextTypes.IContextState = { isOpenInAppMessageOpen: true };

export interface IUserPortalContext {
  isOpenInAppMessageOpen: boolean;
  closeOpenInAppMsg: Function;
}

export const UserPortalContext = createContext<IUserPortalContext>(undefined);

export default function ContextStore({ children }) {
  const [state, dispatch] = useReducer(ContextReducer, initailState);

  const closeOpenInAppMsg = () => {
    hideOpenInAppMsgAction(dispatch);
  };

  const contextValue = useMemo<IUserPortalContext>(() => {
    return {
      isOpenInAppMessageOpen: state.isOpenInAppMessageOpen,
      closeOpenInAppMsg,
    };
  }, [state.isOpenInAppMessageOpen]);

  return <UserPortalContext.Provider value={contextValue}>{children}</UserPortalContext.Provider>;
}
