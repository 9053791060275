import React, { useCallback, useContext, useState } from 'react';
import { BrandConfiguration } from '@toca.ui/config';

type TBrandContext = {
  switchBrand?: (brandId: string) => void;
  brandProfileOption: BrandConfiguration.BrandProfileOption;
} & BrandConfiguration.BrandDetails;
const BrandContext = React.createContext<TBrandContext | null>(null);

export const BrandContextProvider = (props: { children: React.ReactNode }) => {
  const [brand, setBrand] = useState<BrandConfiguration.BrandDetails>(
    BrandConfiguration.GetBrandConfiguration()
  );

  const [brandProfileOption, setBrandProfileOption] = useState(
    BrandConfiguration.getBrandProfileOption()
  );

  const switchBrand = useCallback((brandId: string) => {
    const brands = BrandConfiguration.getBrandsList();
    const brand = brands.find((brand) => brand.brandConfiguration.brandId === brandId);
    if (brand) {
      setBrand(brand);
      setBrandProfileOption(BrandConfiguration.getBrandProfileOption());
    }
  }, []);

  return (
    <BrandContext.Provider value={{ ...brand, brandProfileOption, switchBrand }}>
      {props.children}
    </BrandContext.Provider>
  );
};

export const useBrandContext = () => {
  const ctx = useContext(BrandContext);

  if (!ctx) {
    throw new Error('useBrandContext must be called inside a BrandContextProvider');
  }

  return ctx;
};
